import React from 'react'
import styled from 'styled-components'
import walkCycle from '../pages/projects/images/walk-cycle.gif'
import Canvas from './Canvas'
import Animation from './Animation'
import posed from "react-pose"
import { Link } from 'gatsby'

const AnimationWrap = styled.div`
  width: 11rem;
  height: 11rem;

  img {
    height: 100%;
  }

  svg {
    path {
      transition: stroke 1s;
      stroke: ${props => props.isOpen ? 'gray' : '#26E0A8'};
    }
  }
`

const CanvasBackground = styled.div`
  background: #014F97;
  max-width: 100%;
  height: max-content;

  canvas {
    max-width: 100%;
    display: block;
    max-height: 11.2rem;
  }
`

class PreviewContent extends React.Component {
  render() {
    const { slug, autoplay, isHovering, isOpen } = this.props

    switch(slug) {
      case 'canvas-experiments':
        return (
          <CanvasBackground>
            <Canvas name={slug} type={`thumbnail`}/>
          </CanvasBackground>
        )
      case 'animations':
        return (
          <AnimationWrap>
            <img src={walkCycle} alt=""/>
          </AnimationWrap>
        )
      case 'gibraltar':
        return (
          <AnimationWrap isOpen={this.props.isOpen}>
            <Animation pauseAble name={slug} isPlaying={isHovering && !isOpen} />
          </AnimationWrap>
        )
      case 'teem':
        return (
          <AnimationWrap isOpen={isOpen}>
            <Animation pauseAble name={slug} autoplay={autoplay} isPlaying={autoplay || (isHovering && !isOpen)} />
          </AnimationWrap>
        )
      default:
        return null
    }
  }
}

const TitlePose = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 100
  },
  exit: {
    y: props => props.isCard ? 50 : 0,
    opacity: props => props.isCard ? 0 : 1,
  }
})

const PreviewText = posed.div({
  hover: {
    y: 0,
    opacity: 1,
    delay: props => 30 + props.offset,

  },
  initial: {
    y: 5,
    opacity: 0
  }
})

const Flexbox = styled(TitlePose)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: ${props => props.isCard ? 'initial' : 'space-between'};
`

const DateSpan = styled.span`
  color: gray;
  display: block;
  font-style: italic;
  font-size: ${props => props.isCard ? '1rem' : '1.2rem'};
`

const CardText = styled.div`
  padding: .7rem;
  margin-left: ${props => props.isCard ? 0 : '-.7rem'};

  h1 {
    color: #3E9BDD;
    margin: 0;
    margin-bottom: .5rem;
  }

  p {
    margin: .3em 0;
  font-size: ${props => props.isCard ? '1.2rem' : '1.4rem'};;
  }
`

const Preview = ({isCard, isHovering = false, isOpen, frontmatter: { title, description, startDate, endDate, slug }}) => {
  const pathname = `projects/${slug}/`

  const dateString =
  new Date(startDate).getFullYear() +
  String(
    endDate ?
      ` – ${endDate !== 'now' ?
      new Date(endDate).getFullYear() :
      ''}` :
      ''
  )

  return (
    <div>
      <Flexbox isCard={isCard}>
        <CardText isCard={isCard}>
          { isCard ? (
            <Link state={{isModal: true}} to={pathname}>{title}</Link>
          ) : (
            <h1>{title}</h1>
          )}
          <div>
            <PreviewText isCard={isCard} offset={0}><DateSpan isCard={isCard}>{dateString}</DateSpan></PreviewText>
            <PreviewText isCard={isCard} offset={30}><p>{description}</p></PreviewText>
          </div>
        </CardText>
        <PreviewContent slug={slug} autoplay={!isCard} isHovering={isHovering} isOpen={isOpen}/>
      </Flexbox>
    </div>
  )
} 

export default Preview
