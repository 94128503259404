import React from 'react'

const getPath = name => `/${name.substr(0, name.indexOf('/') !== -1 ? name.indexOf('/') : name.length)}.json`

class Animation extends React.Component {
  waitForIt = ({animation}) => {
    const minFrame = animation.name === 'teem' ? 80 : 0
    const { currentFrame } = animation

    if (typeof currentFrame !== 'undefined' && !animation.isPaused && (currentFrame < minFrame || currentFrame > 240)) {
      setTimeout(() => this.waitForIt({animation}), 500);
      return false
    }

    animation.pause()
  }

  componentDidUpdate(prevProps) {
    if (window.lottie && window.lottie.getRegisteredAnimations) {
      const animation = window.lottie.getRegisteredAnimations().find(a => a.name === this.props.name)
      if (this.props.isStopped) {
        animation.stop()
      } else if (prevProps.isPlaying !== this.props.isPlaying) {
        if(!prevProps.isPlaying && this.props.isPlaying) {
          animation.play()
        } else if(!this.props.isPlaying && this.props.pauseAble) {
          this.waitForIt({animation})
        } else {
          animation.pause()
        }
      }
    }
  }

  componentDidMount() {
    if (window.lottie && window.lottie.loadAnimation) {
      window.lottie.loadAnimation({
        container: document.getElementById(this.props.name + 'lottie'), // the dom element that will contain the animation
        renderer: 'svg',
        name: this.props.name,
        loop: true,
        autoplay: this.props.autoplay,
        path: getPath(this.props.name)
      })

      if (this.props.autoplay) {
        const animation = window.lottie && window.lottie.getRegisteredAnimations().find(a => a.name === this.props.name)
        animation && this.waitForIt({animation})
      }
    } else {
      if (window.lottieAnimations) {
        window.lottieAnimations.push({name: this.props.name, autoplay: this.props.autoplay})
      } else {
        window.lottieAnimations = [{name: this.props.name, autoplay: this.props.autoplay}]
      }

      const script = document.getElementById('lottie') || (() => {
        const newScript = document.createElement('script')

        newScript.src = '/lottie.js'
        newScript.id = 'lottie'
        newScript.async = true

        return newScript
      })()

      script.onload = () => {
        window.lottieAnimations.forEach(({name, autoplay}, index) => {
          const animation = window.lottie.loadAnimation({
            container: document.getElementById(name + 'lottie'), // the dom element that will contain the animation
            renderer: 'svg',
            name,
            loop: true,
            autoplay,
            path: getPath(name)
          })

          if (autoplay) {
            animation.play()
            this.waitForIt({animation})
          }
        })
      }

      document.body.appendChild(script)
    }

  }

  componentWillUnmount() {
    window.lottie.getRegisteredAnimations().find(a => a.name === this.props.name).destroy()
  }

  render() {
    return <span id={this.props.name + 'lottie'}></span>
  }
}

export default Animation
