import React from "react";
import ProjectCard from "./ProjectCard";
import styled from "styled-components";
import Link from "./Link";
const Section = styled.section`
  margin-bottom: 6em;
`;

const Container = styled.div`
  padding-top: 3vh;
  margin-bottom: 60vh;
`;

class Projects extends React.Component {
  render() {
    const pageChildren = this.props.pageChildren || null;

    return (
      <Container id="projects">
        <h1>Projects</h1>

        {/* <p>
          This is a selection of my work, which (you can sort by … | is now sorted to show …)
        </p> */}

        <Section>
          <h2>Code</h2>I started learning to code with projects like
          <ProjectCard
            pageChildren={pageChildren}
            slug="canvas-experiments"
            size="half"
          />
          and
          <ProjectCard
            pageChildren={pageChildren}
            slug="ratios"
            size="half"
            suffix="."
          />
          <Link href="https://formable.tools" animationKey="main">
            Formable
          </Link>{" "}
          initially started off as
          <ProjectCard pageChildren={pageChildren} slug="votre" suffix="." />
          My second biggest project was definitely
          <ProjectCard pageChildren={pageChildren} slug="findbetterquestions" />
          while
          <ProjectCard
            pageChildren={pageChildren}
            slug="typic-bid"
            size="half"
          />
          is another web-app I made.
          <br />
          From 2018 to 2021 I worked along an amazing team on
          <ProjectCard
            pageChildren={pageChildren}
            slug="meistertask"
            size="half"
            suffix="."
          />
          Some other things I created which you can view on the web are
          <ProjectCard
            pageChildren={pageChildren}
            slug="daily-photo"
            size="half"
            suffix=","
          />
          <ProjectCard
            pageChildren={pageChildren}
            slug="das-project"
            size="half"
          />
          and
          <ProjectCard
            pageChildren={pageChildren}
            slug="chrome-extension"
            size="half"
            suffix="."
          />
        </Section>

        <Section id="video">
          <h2>Video</h2>
          Besides Code, I also made some videos, including
          <ProjectCard
            pageChildren={pageChildren}
            slug="teem"
            size="half"
            suffix=","
          />
          <ProjectCard
            pageChildren={pageChildren}
            slug="gibraltar"
            size="half"
          />
          and also some
          <ProjectCard
            pageChildren={pageChildren}
            slug="animations"
            size="half"
            suffix="."
          />
        </Section>

        {/* <Section>
          If you don’t like moving things, you can take a look at my
          <ProjectCard pageChildren={pageChildren} slug="stills" size="half"/>
          or my Unsplash profile.
        </Section> */}
      </Container>
    );
  }
}

export default Projects;
