import React from 'react'
import styled from 'styled-components'

const StyledCanvas = styled.canvas`
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
  display: block;
`

export default class Canvas extends React.Component {
  renderPentagram = canvas => {
    // resizeCanvasToDisplaySize(canvas)
    let ctx = canvas.getContext("2d")
    if (this.props.name === 'pentagram') {
      ctx.canvas.width  = 1000;
      ctx.canvas.height = 1000;
      canvas.style.backgroundColor = `#014F97`
    }
    // ctx.canvas.width  = window.innerWidth;
    // ctx.canvas.height = window.innerHeight;
    let center_y = canvas.height/2;
    let center_x = canvas.width/2;
    let isntoutlined = true;
    let linelength = 450;
    let linelengthgl = 450;
    let star = [2,4,1,3,5]; //where to go to, to draw star
    let direction = 1;
    let pentaanzahl = 1;

    function draw(){
      // resizeCanvasToDisplaySize(canvas)
      ctx.clearRect(0,0,canvas.width,canvas.height);
      for (let o = 1; o <= pentaanzahl; o++) {
        ctx.beginPath();
        ctx.moveTo (center_x +  direction * linelength * Math.cos(0), center_y +  linelength *  Math.sin(0));

        if (isntoutlined){
        for (let i = 1; i <= 5;i ++) {
            ctx.lineTo (center_x + direction * linelength * Math.cos(i * 2 * Math.PI / 5), center_y + linelength * Math.sin(i * 2 * Math.PI / 5));
        }}

        for (let ii = 0; ii <= 4; ii++) {
          ctx.lineTo (center_x + direction * linelength * Math.cos(star[ii] * 2 * Math.PI / 5), center_y + linelength * Math.sin(star[ii] * 2 * Math.PI / 5));
        };

        ctx.strokeStyle = "#E2F2FF";
        ctx.stroke();

        //fibonacci50/48: 12.586.269.025/4.807.526.976=2,6180339887
        isntoutlined = false;
        direction *= -1;
        linelength /= 2.6180339887;
      }
      isntoutlined = true;
      direction = 1;

      linelengthgl *= 1.5;
      linelength = linelengthgl;
      pentaanzahl += 1;

    }
    canvas.addEventListener("click", draw, true);

    draw()
    draw()
    draw()
    draw()
    draw()
  }

  renderSquares = canvas => {
    canvas.style.backgroundColor = `#014F97`
    let context = canvas.getContext("2d");

		let rh = 20; //rect_height&width
		let spacer = 50; //space between rects

		let htbdw = 1; //has_to_be_drawn_width
		let htbdh = 1; //has_to_be_drawn_height
		let htbd = 1; //has_to_be_drawn

  	context.canvas.width  = 1000;
  	context.canvas.height = 1000;

  	let clicked = 0;

  	function double(){
  		if (clicked < 15){
  			let middle_y = canvas.height/2-rh/2;
  			let middle_x = canvas.width/2-rh/2;

  			context.clearRect(0,0,canvas.width,canvas.height);
  			// isd = 0;

  			let drawing_height = middle_y-(htbdh/2*spacer-spacer/2);
  			let drawing_width = middle_x-(htbdw/2*spacer-spacer/2);
  			let drawing_width_start = drawing_width;

  				for (let isd = 0; isd < htbd; isd++) { //is_drawn
  					if (isd % htbdw === 0 && isd !== 0) {
  						drawing_height += spacer;
  						drawing_width = drawing_width_start;
  					};

  					context.fillStyle="#F0AA4B";
  					context.fillRect(drawing_width,drawing_height,rh,rh);
  					drawing_width += spacer;
  				};

  			//has to be drawn ändern
  			if (htbdw===htbdh){
  				htbdw*=2;
  			}
  			else{
  				htbdh*=2;
  			};

  			htbd = htbdw * htbdh;

  			rh /= 1.2;
  			spacer /= 1.2;
  			clicked ++;
  		}
  	};

  	canvas.addEventListener("click", double, true);
  }

  componentDidMount() {
    let canvas = document.getElementById(`canvas${this.props.name}`)

    if (canvas) {
      if(this.props.name === 'squares') {
        this.renderSquares(canvas)
      } else {
        this.renderPentagram(canvas)
      }
    }
  }

  render() {
    return this.props.type === `thumbnail` ?
      <canvas id={`canvas${this.props.name}`} width="500" height="500"/> :
      <StyledCanvas id={`canvas${this.props.name}`} width="500" height="500"/>
  }
}
