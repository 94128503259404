import React from 'react'
import Lottie from './Lottie'

export default class Animation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      renderAnimation: false
    }
  }

  componentDidMount() {
    this.setState({renderAnimation: true})
  }

  render() {
    return this.state.renderAnimation ? (
      <Lottie
        name={this.props.name}
        isPlaying={this.props.isPlaying}
        pauseAble={this.props.pauseAble}
        isStopped={this.props.isStopped}
        autoplay={this.props.autoplay}
      />
    ) : null
  }
}
