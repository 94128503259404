import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import isRelativeUrl from 'is-relative-url';
import Animation from './Animation';

const EucalyptusText = styled.span`
  color: #26e0a8;
`;

const Underlined = styled.span`
  cursor: pointer;

  a {
    color: #3e9bdd;
    text-decoration: none;
  }

  position: relative;

  a::after {
    position: absolute;
    content: '';
    background-color: #3e9bdd;
    top: 100%;
    height: 2px;
    width: 100%;
    left: 0;
    transition: 0.5s ease transform, 0.45s cubic-bezier(0, 0, 0.25, 1) opacity;
    transform: scaleX(0);
    transform-origin: ${(props) => (props.exitRight ? 'right' : 'left')};
    opacity: 0;
  }

  &:hover {
    a::after {
      transform: scaleX(1);
      transform-origin: left;
      opacity: 1;
      transition: 0.5s ease transform, 0s cubic-bezier(0, 0, 0.25, 1) opacity;
    }
  }
`;

const ExternalLinkWrap = styled(Underlined)`
  a::after {
    background-color: #26e0a8;
  }

  svg {
    opacity: 0;
    transition: 0.3s ease all;
  }

  &:hover {
    svg {
      transition: 0.3s ease all 0.2s;
      opacity: 1;
    }
  }
`;

const AnimationWrap = styled.span`
  position: relative;
  z-index: 100;
  display: ${(props) => (props.isStopped ? 'none' : 'initial')};

  svg {
    background: #fcfcfc;
    position: absolute;
    z-index: 100;
    height: 1.2em !important;
    width: 1.2em !important;
    display: inline;

    path {
      stroke-width: 11px;
      stroke: #26e0a8;
    }
  }
`;

export default class UnderlinedLink extends React.Component {
  state = {
    isPlaying: false,
    isStopped: false,
    exitRight: false,
    outTimeout: false,
    inTimeout: false,
    stopTimeout: false,
    startTimeout: false,
  };

  handleMouseEnter = () => {
    clearTimeout(this.state.outTimeout);
    const startTimeout = setTimeout(() => {
      clearTimeout(this.state.outTimeout);
      this.setState({ isPlaying: true, isStopped: false });
    }, 200);
    const stopTimeout = setTimeout(() => {
      this.setState({ isPlaying: false });
    }, 900);
    const inTimeout = setTimeout(() => {
      this.setState({ exitRight: true });
    }, 300);
    this.setState({ inTimeout, startTimeout, stopTimeout });
  };

  handleMouseLeave = () => {
    clearTimeout(this.state.inTimeout);
    const outTimeout = setTimeout(() => {
      this.setState({ isStopped: true, exitRight: false });
    }, 300);
    this.setState({ outTimeout });
  };

  componentWillUnmount = () => {
    clearTimeout(this.state.outTimeout);
    clearTimeout(this.state.inTimeout);
    clearTimeout(this.state.stopTimeout);
    clearTimeout(this.state.startTimeout);
  };

  render() {
    const {
      children,
      href,
      animationKey,
      to,
      useRelativeStyle,
      hostRef,
      style,
      ...props
    } = this.props;

    if (href && href.includes('static/')) {
      return (
        <a target="_blank" rel="noopener" href={href}>
          {children}
        </a>
      );
    }

    if (useRelativeStyle) {
      return (
        <Underlined
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          exitRight={this.state.exitRight}
        >
          <a target="_blank" rel="noopener" href={href}>
            {children}
          </a>
        </Underlined>
      );
    }

    if (isRelativeUrl(href || to)) {
      return (
        <Underlined
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          exitRight={this.state.exitRight}
        >
          <Link to={href || to} {...props}>
            {children}
          </Link>
        </Underlined>
      );
    }

    return (
      <ExternalLinkWrap
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        exitRight={this.state.exitRight}
      >
        <a target="_blank" rel="noopener" href={href}>
          <EucalyptusText>
            {children}
            <AnimationWrap
              isStopped={this.state.isStopped}
              isPlaying={this.state.isPlaying}
            >
              <Animation
                name={`arrow/${href}/${animationKey ? animationKey : ''}`}
                isPlaying={this.state.isPlaying}
                isStopped={this.state.isStopped}
                autoplay={false}
              />
            </AnimationWrap>
          </EucalyptusText>
        </a>
      </ExternalLinkWrap>
    );
  }
}
