import React from "react";
import styled from "styled-components";
import Link from "./Link";

const RedText = styled.span`
  color: #ef3d21;
`;

const WavingHeading = styled.h1`
  background-color: none;
  border: none;

  height: 1.5em;
  width: 1.5em;

  font-size: 2em;

  cursor: pointer;

  user-select: none;

  &:active,
  &:hover,
  &:focus {
    animation: rotation 0.35s 4 alternate backwards;
    animation-timing-function: ease-in-out;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(30deg);
    }
  }
`;

class About extends React.Component {
  render() {
    return (
      <div>
        <WavingHeading>
          <span role="img" aria-label="waving hand" aria-hidden="true">
            👋
          </span>
        </WavingHeading>
        <p>
          My name is <RedText>Emil</RedText>. <br />
        </p>
        <p>
          I’m a freelance web developer and the creator of{" "}
          <Link href="https://formable.tools" animationKey="title">
            Formable
          </Link>{" "}
          (a tool for thought).
        </p>
        <p>
          You can also have a look at{" "}
          <Link to="/projects/" state={{ scrollToProjects: true }}>
            some older projects
          </Link>
          .
        </p>
        <p>
          I sometimes post stuff on{" "}
          <Link href="https://twitter.com/emilbruckner">Twitter</Link> and{" "}
          <Link href="https://www.instagram.com/emilbruckner/">Instagram</Link>.{" "}
          I’m not really active on{" "}
          <Link href="https://medium.com/@emilbruckner">Medium</Link> or{" "}
          <Link href="https://unsplash.com/@emilbruckner">Unsplash</Link>.<br />
          You can always drop me a line at{" "}
          <Link useRelativeStyle href="mailto:hi@emilbruckner.com">
            hi@emilbruckner.com
          </Link>
          .
        </p>
        <p>
          {/* Thanks to the Austrian mandatory military service, my last job was to work in a Kindergarten for 9 months. */}
          {/* You can currently <a target="_blank" href="">hire me for …</a> */}
        </p>
      </div>
      // <div>
      //   <Link to="/projects">Projects</Link>
      //   <p>
      //     This is a selection of my work, which (you can sort by … | is now sorted to show …)
      //   </p>
      //
      //   projects are in text, but project-links are cards with short description
      //   <p>
      //     <span>Code</span>
      //     I started learning to code in 2013, with projects like
      //     Pentagon/Squared and
      //     itgr and wmfr.
      //     My two biggest projects now are definitely
      //     Find Better Questions and
      //     Votre, while
      //     typic.bid is another web-app I made.
      //     {/* mention spindst du in typid.bid */}
      //     Some other things I created which you can view on the web are
      //     daily photo,
      //     dasProject, and
      //     Duo.
      //   </p>
      //
      //   <p>
      //     Besides Code, I also made some videos, including
      //     Teem
      //     Gibraltar
      //     My Idol Ad
      //     Bunt
      //
      //
      //     a video for das Project,
      //     and also some animations (page: fbq, in school I created some pretty random things like Being to Late: 911, maybe infographic, walk cycle, object characteristics, wind energy, top3 (talk more about code there))
      //   </p>
      //
      //   <p>
      //     If you don’t like moving things, you can watch some mores stuff [here (tolino stuff …, 3D)] or on Unsplash.
      //   </p>
      // </div>
    );
  }
}

export default About;
