import React from 'react';
import { StaticQuery, graphql, Link, navigate } from 'gatsby';
import styled from 'styled-components';
import Preview from './Preview';
import posed, { PoseGroup } from 'react-pose';
import { Consumer } from './Context';
import { FaTimes } from 'react-icons/fa';
import { debounce, isElementInViewport } from '../utils/';

const topOfPrevCardIsVisible = () => {
  return typeof window !== `undefined` && window.previouslyOpenCardTop >= 0;
};

const getInitialClosingHeight = (props) => {
  const { element } = props;
  return topOfPrevCardIsVisible(element) ? element.clientHeight : 0;
};

const ClosePose = posed.div({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
    boxShadow: '0px 0px 0px rgba(0,0,0,0)',
  },
  hover: {
    scale: 1.1,
    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
  },
  press: {
    scale: 1,
    boxShadow: '0px 1.5px 4px rgba(0,0,0,0.1)',
  },
});

const Close = styled(ClosePose)`
  cursor: pointer;
  right: 0px;
  position: absolute;
  left: auto;
  width: 1.3em;
  border-radius: 1em;
  height: 1.3em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: 0;
  float: right;
  margin: 0.85em;
  z-index: 50;
`;

const CloseBox = styled.div`
  position: sticky;
  top: 0;
  z-index: 50;
`;

const CardPose = posed.div({
  label: 'card',
  // init: {
  //   position: 'absolute',
  //   y: 0,
  //   display: 'none',
  //   height: 'auto'
  // },
  // enter: {
  //   y: 0
  // },
  // exit: {
  //   y: 0
  // },

  initial: {
    applyAtStart: {
      position: 'absolute',
      height: 'auto',
    },
    applyAtEnd: {
      display: 'none',
    },
    position: 'absolute',
    // width: 'auto',
    height: 'auto',
    display: 'none',
    childOpacity: 0,
    opacity: 0,
    childY: +5,
    background: 'rgba(255, 255, 255, 0)',
    // flip: true,
    y: 0,
    boxShadow: '0px 0px 0px rgba(0,0,0,0)',
  },
  close: {
    applyAtEnd: {
      display: 'none',
    },
    applyAtStart: {
      position: 'absolute',
      height: (props) => getInitialClosingHeight(props),
      opacity: (props) => (topOfPrevCardIsVisible(props.element) ? 1 : 0),
    },
    height: 'auto',
    opacity: 0,
    // width: '100%',
    // width: 'auto',
    // afterChildren: true,
    // height: 'auto',
    transition: {
      // duration: 2000
    },
  },
  fullscreen: {
    applyAtStart: {
      position: 'static',
      display: 'block',
      width: '100%',
    },
    // applyAtEnd: {
    //   position: 'absolute'
    // },
    // top: 0,
    opacity: 1,
    height: 'auto',
    background: 'rgba(255, 255, 255, 1)',
    // transition: { duration: 2000 },
    // flip: true,
    y: 0,
    boxShadow: '0px 5px 10px rgba(0,0,0,0.1)',
  },
  hover: {
    applyAtStart: {
      position: 'absolute',
      display: 'block',
      height: 'auto',
      opacity: 1,
    },
    opacity: 1, //needed
    height: 'auto', //needed?
    childOpacity: 1,
    childY: 0,
    // width: 'fit-content',
    // height: 'auto',
    background: 'rgba(255, 255, 255, 1)',
    y: -4,
    boxShadow: '0px 5px 10px rgba(0,0,0,0.2)',
  },
});

const Card = styled(CardPose)`
  display: ${(props) => (props.isOpen ? 'block !important' : 'none')};
  z-index: 1;
  top: 0;
  background: white;

  border-radius: 3px;

  margin: 0em -0.7rem;
  margin-top: -0.7rem;
`;

const InlinePose = posed.div({
  hover: {
    // applyAtStart: {
    //   opacity: 0
    // },
    y: -4,
  },
  close: {
    // delay: 200,
    y: 0,
  },
  initial: {
    y: 0,
  },
  fullscreen: {
    y: 0,
  },
});

const Inline = styled(InlinePose)`
  width: fit-content;
`;

const ContentPose = posed.div({
  // preEnterPose: {
  //   y: -200
  // },
  // init: {
  // },
  // initial: {
  //   y: 0,
  //   opacity: 1,
  //   // delay: 100
  // },
  // fullscreen: {
  //   y: 0,
  //   opacity: 1,
  // transition: { duration: 2000 }
  // },
  // initial: {
  //   y: -100,
  //   opacity: 0,
  // transition: { duration: 2000 }
  // },
  // close: {
  //   opacity: 0,
  // transition: { duration: 2000 },
  //   // flip: false,
  //   applyAtEnd: {
  //     display: 'none',
  //     width: 0,
  //     margin: 0
  //   }
  // },
  exit: {
    applyAtStart: {
      height: (props) => getInitialClosingHeight(props),
      opacity: (props) => (topOfPrevCardIsVisible(props.element) ? 1 : 0),
    },
    height: 0,
    y: -20,
    opacity: 0,
    // transition: { duration: 2000 },
    // flip: false,
    display: 'none',
    // applyAtEnd: {
    //   display: 'none',
    //   width: 0,
    //   margin: 0,
    // }
  },
  enter: {
    height: 'auto',
    y: 0,
    opacity: 1,
    // transition: { duration: 2000 },
    // width: 'auto',
    // applyAtStart: {
    //   width: 'auto'
    // }
    // flip: false,
    // applyAtStart: {
    //   display: 'block'
    // }
    // applyAtEnd: {
    //   width: 'auto',
    //   margin: 'auto'
    // }
  },
  preEnter: {
    height: 'auto',
    y: 0,
    opacity: 1,
    // transition: { duration: 2000 },
    // width: 'min-content',
  },
  // preEnter: {
  //   height: 0,
  //   y: -100,
  //   opacity: 0,
  // transition: { duration: 2000 },
  // flip: false
  // },
});

const Content = styled(ContentPose)`
  margin: 0.7em;
  overflow: hidden;
`;

const WrapPose = posed.div({
  initial: {
    afterChildren: true,
    applyAtEnd: {
      width: 'fit-content',
    },
    flip: false,
  },
  close: {
    afterChildren: true,
    applyAtEnd: {
      width: 'fit-content',
    },
    flip: false,
  },
  hover: {
    applyAtStart: {
      width: 'initial',
    },
    flip: false,
  },
  fullscreen: {
    applyAtStart: {
      width: 'initial',
    },
  },
});

const Wrap = styled(WrapPose)`
  position: relative !important;
  width: fit-content;
  cursor: ${(props) => (props.isOpen ? 'initial' : 'pointer')};
`;

const getCard = ({ slug }) => document.getElementById(`project-card/${slug}`);

export default class Project extends React.Component {
  state = {
    isHovering: false,
    justClosed: false,
    justjustClosed: false,
    justLeftHover: false,
    mouseLeaveTimeout: false,
    justClosedTimeout: false,
    justjustClosedTimeout: false,
    wasTouched: false,
  };

  handleTouchStart = () => {
    this.setState({ wasTouched: true });
  };

  handleMouseEnter = () => {
    if (
      (!this.state.justLeftHover || this.state.justClosed) &&
      !this.state.wasTouched
    ) {
      this.setState({ isHovering: true });
      // TODO: if hover directly after close, stop timeouts, do them now, and but it right back into hover state
    }
  };

  handleMouseLeave = () => {
    this.setState({ isHovering: false, justLeftHover: this.state.isHovering });
    const mouseLeaveTimeout = setTimeout(() => {
      this.setState({ justLeftHover: false });
    }, this.state.justClosed ? 500 : 300);
    this.setState({ mouseLeaveTimeout });
  };

  closeCard = ({ slug, skipNavigate }) => {
    this.setState({
      isHovering: false,
      justLeftHover: false,
      justClosed: true,
      justjustClosed: true,
    });

    const justClosedTimeout = setTimeout(() => {
      this.setState({ justClosed: false });
    }, 500);
    const justjustClosedTimeout = setTimeout(() => {
      this.setState({ justjustClosed: false });
    }, 10);

    if (!skipNavigate) {
      navigate(`/projects/`);
    }

    this.setState({ justClosedTimeout, justjustClosedTimeout });
  };

  componentDidMount() {
    const { pageChildren } = this.props;

    if (pageChildren && pageChildren.key.slice(10, -1) === this.props.slug) {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { pageChildren } = this.props;
    const { pageChildren: nextPageChildren } = nextProps;

    const pageSlug = pageChildren && pageChildren.key.slice(10, -1);
    const nextPageSlug = nextPageChildren && nextPageChildren.key.slice(10, -1);

    if (
      nextPageChildren &&
      nextPageChildren.key !== this.props.pageChildren.key
    ) {
      if (pageChildren && pageSlug === this.props.slug) {
        window.removeEventListener('scroll', this.handleScroll);
        this.closeCard({ skipNavigate: true });
      } else if (nextPageChildren && nextPageSlug === this.props.slug) {
        window.addEventListener('scroll', this.handleScroll);
      }
    }

    return true;
  }
  // TODO: close button can escape modal
  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
    clearTimeout(this.state.mouseLeaveTimeout);
    clearTimeout(this.state.justClosedTimeout);
    clearTimeout(this.state.justjustClosedTimeout);
  };

  handleScroll = debounce(() => {
    const { slug } = this.props;
    const isInViewport = isElementInViewport(getCard({ slug }));

    if (!isInViewport) {
      window.removeEventListener('scroll', this.handleScroll);
      this.closeCard({ slug });
    }
  }, 100);

  handleClick = (isOpen) => {
    const { slug } = this.props;
    if (!isOpen) {
      // window.addEventListener('scroll', this.handleScroll)
      navigate(`/projects/${slug}/`, {
        state: window.___WAS_TOUCHED ? {} : { isModal: true },
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { slug, size, position, pageChildren, suffix } = this.props;
    const { isHovering, justClosed, justjustClosed } = this.state;

    return (
      <Consumer>
        {({ openProject }) => {
          return (
            <StaticQuery
              query={graphql`
                query {
                  allMarkdownRemark {
                    edges {
                      node {
                        frontmatter {
                          title
                          description
                          slug
                          startDate
                          endDate
                        }
                      }
                    }
                  }
                }
              `}
              // TODO: can we use data from project template, instead of loading everything here?
              render={(data) => {
                const {
                  node: { frontmatter },
                } = data.allMarkdownRemark.edges.find(
                  (edge) => edge.node.frontmatter.slug === slug
                );
                const { title } = frontmatter;

                const locationState =
                  pageChildren && pageChildren.props.location.state;
                const isModal = locationState && locationState.isModal;
                const isOpen = openProject === slug && isModal;
                // if (isOpen) debugger
                const justClosedNotOpen = justClosed && !isOpen;
                // const pose = isOpen ? 'fullscreen' : isHovering ? 'hover' : 'initial'
                const pose = isOpen
                  ? 'fullscreen'
                  : justClosedNotOpen
                    ? 'close'
                    : isHovering
                      ? 'hover'
                      : 'initial';
                const pathname = `projects/${slug}/`;
                // const poseKey = `${slug}${isOpen}`

                const showAnimation = topOfPrevCardIsVisible();
                const showCard = !justClosedNotOpen || showAnimation;

                const previewData = {
                  frontmatter,
                  isHovering,
                  isOpen,
                  isCard: true,
                };

                // TODO enter directly after close doesn’t put car dto hover state
                return (
                  <Wrap
                    key={`project-card-wrap/${slug}`}
                    id={`project-card/${slug}`}
                    className={isOpen ? 'open-card' : ''}
                    isOpen={isOpen}
                    onMouseEnter={this.handleMouseEnter}
                    onTouchStart={this.handleTouchStart}
                    onMouseLeave={this.handleMouseLeave}
                    withParent={false}
                    pose={pose}
                  >
                    {/* {!isOpen && */}
                    <span
                      style={{
                        display:
                          isOpen || (justjustClosed && showAnimation)
                            ? 'none'
                            : 'inline',
                      }}
                    >
                      <Inline withParent={false} pose={pose}>
                        <Link
                          state={this.state.wasTouched ? {} : { isModal: true }}
                          to={pathname}
                        >
                          {title}
                        </Link>
                        {suffix}
                      </Inline>
                    </span>
                    {/* } */}
                    <div
                      style={
                        !showCard
                          ? {
                              display: 'none',
                            }
                          : null
                      }
                    >
                      <Card
                        size={size}
                        isOpen={isOpen}
                        onMouseLeave={this.handleMouseLeave}
                        // poseKey={poseKey}
                        onClick={() => this.handleClick(isOpen)}
                      >
                        {isOpen && (
                          <CloseBox
                            onClick={() => {
                              this.closeCard({ slug });
                            }}
                          >
                            <Close>
                              <FaTimes />
                            </Close>
                          </CloseBox>
                        )}
                        <Preview {...previewData} />
                        <PoseGroup flipMove={false} preEnterPose={'preEnter'}>
                          {isOpen && [
                            <Content
                              key={`project-card-content/${slug}/${isOpen}`}
                            >
                              {pageChildren}
                            </Content>,
                          ]}
                        </PoseGroup>
                      </Card>
                    </div>
                  </Wrap>
                );
              }}
            />
          );
        }}
      </Consumer>
    );
  }
}
