import React from "react";
import Helmet from "react-helmet";
import Link from "./Link";
import About from "./About";
import Projects from "./Projects";
import posed, { PoseGroup } from "react-pose";
import { tween } from "popmotion";
import { Provider } from "./Context";
import "typeface-karla";
import "typeface-crimson-text";
import styled from "styled-components";
import Preview from "./Preview";

import "./layout.css";

const Container = posed.div({});

const NavContainer = posed.div({});

const Content = posed.div({
  enter: { opacity: 1 },
  // enter: { opacity: 1, delay: 100, beforeChildren: 300 },
  exit: { opacity: 0 },
});

const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;

  span {
    margin-right: 4rem;
  }
`;

const LinkWrap = React.forwardRef((props, ref) => (
  <Link ref={ref} {...props} />
));

const NavItem = posed(LinkWrap)({
  in: { opacity: 1 },
  out: { opacity: 0 },
});

const Footer = styled.span`
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  font-size: 0.8rem;
  padding-bottom: 0.5rem;
`;

class Layout extends React.Component {
  handleTouchStart = () => {
    window.___WAS_TOUCHED = true;
  };

  render() {
    const {
      children,
      location,
      pageContext: { slug },
      data,
    } = this.props;
    // debugger
    const isHome = location.pathname === "/";
    const isProjects = location.pathname.includes("/projects/");
    const isProjectsPage = location.pathname === "/projects/";
    const isProject = isProjects && !isProjectsPage;
    const windowIsDefined = typeof window !== `undefined`;

    const isInitialRoute =
      windowIsDefined && window.___INITIAL_PATHNAME === location.pathname;
    const isDirect = !location.state;
    const isModal =
      location.state &&
      location.state.isModal &&
      windowIsDefined &&
      window.___GATSBYGRAM_INITIAL_RENDER_COMPLETE;
    const showAbout =
      isHome ||
      (windowIsDefined &&
        (window.___INITIAL_PATHNAME === "/" || window.___HAS_BEEN_HOME) &&
        !window.___PREV_IS_PROJECT &&
        (!isInitialRoute || isProjects) &&
        !(isProject && !isModal)); // not suer why I need last part and whether isProjects is right
    const showProjects = isProjectsPage || isModal;
    const justProject = isProject && (!isProjects || !isModal) && !isModal; //don’t use isInitial because mobile

    const animationKey = showAbout || showProjects * 0.12; //show route transition animation on change

    const frontmatter =
      (children.props.data &&
        children.props.data.markdownRemark &&
        children.props.data.markdownRemark.frontmatter) ||
      {};
    const previewData = {
      isOpen: true,
      frontmatter,
    };

    const markdownRemark = data && data.markdownRemark;
    const title =
      isProject &&
      markdownRemark &&
      markdownRemark.frontmatter &&
      markdownRemark.frontmatter.title
        ? `${markdownRemark.frontmatter.title} | Emil Bruckner`
        : "Emil Bruckner";

    return (
      <Provider value={{ openProject: slug, location }}>
        <Helmet
          title={title}
          meta={[
            { name: "description", content: "Emil Bruckner" },
            { name: "keywords", content: "Emil Bruckner, web" },
          ]}
        />
        <div
          style={{
            margin: "0 auto",
            maxWidth: 960,
            padding: "0px 1.0875rem 1.45rem",
            paddingTop: 0,
          }}
          onTouchStart={this.handleTouchStart}
        >
          <PoseGroup
            flipMove={false}
            animateOnMount={true}
            enterPose="in"
            exitPose="out"
          >
            {(!showAbout || justProject) && (
              <NavContainer key="nav">
                <Nav>
                  {/* TODO: use emoji only when supported?, aria */}
                  {!showAbout && (
                    <NavItem key="/" to="/">
                      🏠 Home
                    </NavItem>
                  )}
                  {justProject && (
                    <NavItem key="/projects/" to="/projects/">
                      👨🏼‍💻 Projects
                    </NavItem>
                  )}
                </Nav>
              </NavContainer>
            )}
          </PoseGroup>
          <PoseGroup flipMove={false}>
            <Container key={animationKey}>
              <Content>
                {!isDirect && showAbout && <About />}
                {!isDirect &&
                  showProjects && <Projects pageChildren={children} />}
                {justProject && <Preview {...previewData} />}
                {(justProject || isDirect) && children}
              </Content>
            </Container>
          </PoseGroup>
        </div>
        <Footer>© Today—Emil Bruckner</Footer>
      </Provider>
    );
  }
}

export default Layout;
