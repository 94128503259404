export const debounce = (fn, time) => {
  let timeout;

  return function() {
    const functionCall = () => fn.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  }
}

export const isElementInViewport = element => {
    const { top, bottom } = element.getBoundingClientRect()

    return (
        top <= (window.innerHeight || document.documentElement.clientHeight) &&
        bottom >= 0
    )
}
