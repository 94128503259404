import React from 'react';
import Layout from './src/components/Layout';

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onInitialClientRender = () => {
  window.___GATSBYGRAM_INITIAL_RENDER_COMPLETE = true;
};

const getElementHeight = (query) =>
  document.querySelector(query) && document.querySelector(query).offsetHeight;

const getCard = ({ slug }) => document.getElementById(`project-card/${slug}`);

const getApproxProjectsHeight = ({ slug }) => {
  const closedProjectHeight = getElementHeight(`#projects h2`);
  const card = getCard({ slug });

  return (
    getElementHeight(`#projects`) - card.offsetHeight + closedProjectHeight - 3
  );
};

const getNewPageYOffset = ({ slug }) => {
  const projectsHeight =
    window.projectsHeight || getApproxProjectsHeight({ slug });
  const projectsHeightDifference =
    window.preCloseProjectsHeight - projectsHeight;

  const preClosePageY = window.preClosePageY;
  const newPageYOffset = preClosePageY - projectsHeightDifference;
  return newPageYOffset;
};

const isProject = (pathname) =>
  pathname &&
  pathname.match(/(.*\/projects\/[^#]*\/.*)|(.*\/.*\/projects\/[^#]*\/)/m);

const setProjectsHeight = () => {
  const projects = document.getElementById(`projects`);
  if (projects) {
    window.projectsHeight = projects.offsetHeight;
  }
};

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition,
}) => {
  const { location } = routerProps;
  const prevLocation = prevRouterProps && prevRouterProps.location;
  const { pathname, state } = location;

  const prevIsProject = prevRouterProps && isProject(prevLocation.pathname);
  const prevIsHome = prevRouterProps && prevLocation.pathname === `/`;

  const toProject = isProject(pathname);
  const prevIsProjects =
    prevLocation && prevLocation.pathname.slice(-10).includes('/projects');

  const fromProjectToProjects =
    pathname.includes('/projects/') && prevIsProject;
  const fromProjectsToProject = toProject && prevIsProjects;
  const fromHomeToProjects =
    pathname.includes('/projects/') && prevIsHome && !isProject(pathname);

  if ((prevIsHome || prevIsProjects) && state && state.scrollToProjects) {
    document.getElementById('projects').scrollIntoView({
      block: 'start',
      inline: 'start',
      behavior: 'smooth',
    });

    return false;
  }

  if (window.___WAS_TOUCHED) {
    if (toProject) {
      return true;
    }

    if (location.action === 'POP') {
      const currentPosition = getSavedScrollPosition(location);
      const position = currentPosition || [0, 0];
      // setTimeout(
      //   () =>
      //     window.scrollTo({
      //       left: position[0],
      //       top: position[1],
      //       behavior: 'smooth',
      //     }),
      //   300
      // );

      return false;
    } else {
      // scrollTo 0
      // setTimeout(
      //   () =>
      //     window.scrollTo({
      //       top: 0,
      //       behavior: 'smooth',
      //     }),
      //   300
      // );

      return false;
    }
  }

  if (pathname === `/projects/`) {
    if (window.___GATSBYGRAM_INITIAL_RENDER_COMPLETE && !prevIsProject) {
      setProjectsHeight();
    } else if (!prevRouterProps) {
      setTimeout(() => {
        setProjectsHeight();
      }, 100);
    } else if (prevIsProject) {
      setTimeout(() => {
        setProjectsHeight();
      }, 200);
    }
  }

  if (!prevRouterProps) {
    window.___INITIAL_PATHNAME = pathname;

    return toProject ? [0, 0] : true;
  }

  if (toProject) {
    if (window.___WAS_TOUCHED) {
      return true;
    }

    const slug = pathname.slice(10, -1);
    const card = getCard({ slug });
    const rect = card && card.getBoundingClientRect();
    const top = rect && rect.top;
    const bottom = rect && rect.bottom;

    if (
      (top > window.innerHeight - 300 || bottom < 0) &&
      fromProjectsToProject
    ) {
      const scrollTop = card.offsetTop - 100;

      window.scroll({
        top: scrollTop,
        behavior: 'smooth',
      });

      return false;
    }
  }

  if (fromProjectsToProject) {
    const isInitialRoute = window.___INITIAL_PATHNAME === pathname;
    if (isInitialRoute && (!location.state || !location.state.isModal)) {
      return true; //why? I should add comments on why I do unexplanatory stuff, if it is initial load, it should be on top
    }

    if (window.___WAS_TOUCHED) {
      return true;
    }

    return false;
    // I have to conditionally return the right hting here, still to be firgured out …
    // return getSavedScrollPosition(prevLocation)
    // NOTE: this works kind of ok, but if I scroll programatically to some point,
    // the saved pos of the prev route is incorrect. I’d have to update the saved pos after scrolling manually.
    // It would probably happen automatically if I just returned coordinates and let css handle smooth scrolling
    // open -> scroll -> close -> open: goes to init pos. ok, doesn’t make too much sense like I just did it, because savedPos is only updated on transition, but, wll shouldn’t … ahh, it only updates after I do this manual update here
  }

  const prevIsShownAsPage = !prevLocation.state || !prevLocation.state.isModal;
  if (prevIsShownAsPage) {
    return true;
  }

  if (fromProjectToProjects) {
    const prevSlug = prevLocation.pathname.slice(10, -1);
    const prevCard = getCard({ slug: prevSlug });
    // const { top: prevTop } = prevCard.getBoundingClientRect()
    const prevTop = window.previouslyOpenCardTop;
    const prevBottom = window.previouslyOpenCardBottom;

    const prevTopIsVisible = prevTop >= -40;
    const bottomIsVisible =
      prevBottom <=
      (window.innerHeight || document.documentElement.clientHeight);

    let y;

    if (prevTopIsVisible) {
      return false;
    } else if (bottomIsVisible) {
      y = getNewPageYOffset({ slug: prevSlug });
    } else {
      y = prevCard.offsetTop - 50;
    }

    return [0, y];
  }

  return true;
};

export const onPreRouteUpdate = ({ location }) => {
  if (location.pathname === '/') {
    window.___HAS_BEEN_HOME = true;
  }

  const isProjects = location.pathname.includes('/projects/');
  const isProject = isProjects && !window.___HAS_BEEN_HOME;

  window.___PREV_IS_PROJECT = isProject;

  const card = document.querySelector('.open-card');
  const rect = card && card.getBoundingClientRect();
  if (rect) {
    const { top, bottom } = rect;
    window.previouslyOpenCard = card;
    window.previouslyOpenCardTop = top;
    window.previouslyOpenCardBottom = bottom;
    window.preClosePageY = window.pageYOffset;

    const projects = document.getElementById(`projects`);
    window.preCloseProjectsHeight = projects && projects.offsetHeight;
  }
};
